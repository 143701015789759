<template>
  <div class="flex" style="height: 100vh">
    <div
      class="flex flex-col justify-around hidden md:block md:w-1/3 bg-cream"
      style="height: 100%"
    >
      <div
        class="hidden md:block w-full h-1/3 flex items-start justify-center"
        v-if="windowWidth > 768"
      >
        <img
          v-if="$route.query.token && academy.url_logo"
          :src="academy.url_logo"
          class="hidden w-1/3 mx-auto pt-5 object-scale-down"
          alt="Logo academia"
          ref="academyLogo"
          @load="onImageLoad"
        />
        <div class="mx-auto text-center mt-5" ref="academyLogo-loader">
          <t-loader extraClass="border-primary-900 w-8 h-8" />
        </div>
      </div>
      <div class="w-full h-1/3">
        <img
          src="/static/lif-logo.png"
          class="w-1/2 mx-auto my-auto"
          alt="Logo LIF"
        />
      </div>
      <div class="w-full h-1/3 flex justify-center items-end pb-4">
        <img
          src="/static/ticktaps-logo.svg"
          class="w-1/3"
          alt="Logo Ticktaps"
        />
      </div>
    </div>
    <div
      class="relative block md:flex md:flex-col w-full md:w-2/3 center bg-cream overflow-y-auto"
      ref="formBox"
      :style="`height: 100%; background-image: url('/static/liga-bg.png'); background-size: cover; background-position: center;`"
    >
      <div class="relative mx-auto w-11/12">
        <div class="relative w-full rounded-3xl bg-white shadow-md">
          <div class="p-4 sm:p-8">
            <h1 class="title-text">Registro de jugadores</h1>
            <div class="md:hidden">
              <!-- <router-link to="/inicio">
         <div class="w-1/3 my-4 mx-auto object-scale-down">
            <img src="/static/lif-logo.png" />
          </div>
        </router-link> -->
              <div
                class="w-2/3 sm:w-1/3 my-4 mx-auto object-scale-down"
                v-if="windowWidth <= 768"
              >
                <img
                  v-if="$route.query.token && academy.url_logo"
                  :src="academy.url_logo"
                  class="hidden mx-auto mt-5 object-scale-down"
                  alt="Logo academia"
                  ref="academyLogo"
                  @load="onImageLoad"
                />
                <div class="mx-auto text-center mt-5" ref="academyLogo-loader">
                  <t-loader extraClass="border-primary-900 w-8 h-8" />
                </div>
              </div>
            </div>
            <template v-if="!formSubmitted">
              <h2 class="subtitle-text" v-if="academy.name">
                Academia: {{ academy.name }}
              </h2>
              <div class="flex flex-col justify-center mt-2">
                <div class="steps-content">
                  <Form
                    ref="PlayerForm"
                    :disableInputs="false"
                    :initialLoad="initialLoad"
                    @submit="register"
                    :academy="academy"
                    @scrollTo="scrollTo"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flex flex-col justify-center items-center">
                <icon
                  icon="check-circle"
                  class="mx-auto my-auto text-8xl"
                  style="color: #006400"
                ></icon>
                <h1 class="subtitle-text mt-5 text-xl">
                  ¡Bienvenido(a) a la Liga Internacional!
                </h1>
                <h1 class="subtitle-text mt-5 text-lg">
                  Gracias por terminar tu registro. Tus datos están en proceso
                  de verificación.
                </h1>
                <h1 class="subtitle-text mt-5 text-lg">
                  ¡Mi pasión es el fútbol, mi futuro es
                  <span class="text-red-1100"> Vinotinto</span>!
                </h1>
                <t-button
                  class="mx-auto mt-3 md:my-auto"
                  @click="$router.push('/inicio')"
                  >Volver al inicio</t-button
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./Form.vue"
import debounce from "lodash.debounce"

const notify = () => import("@/utils/notify.js")

export default {
  name: "PlayerSignup",
  components: {
    Form
  },
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      initialLoad: true,
      academy: {},
      windowWidth: window.screen.width,
      formSubmitted: false
    }
  },
  methods: {
    scrollTo({ ref, x, y }) {
      this.$refs[ref].scrollTo(x, y)
    },
    onImageLoad() {
      if (this.$refs["academyLogo-loader"])
        this.$refs["academyLogo-loader"].classList.add("hidden")
      if (this.$refs["academyLogo-loader"][0])
        this.$refs["academyLogo-loader"][0].classList.add("hidden")
      if (this.$refs["academyLogo"])
        this.$refs["academyLogo"].classList.remove("hidden")
      if (this.$refs["academyLogo"][0])
        this.$refs["academyLogo"][0].classList.remove("hidden")
    },
    getAcademy() {
      this.token = this.$route.query.token

      if (!this.token) {
        this.initialLoad = false
        return this.$snotify.error(
          "Asegúrate de usar el link provisto por la academia",
          "El link no es válido",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.$store
        .dispatch("global/getItems", {
          route: "/academy/all",
          noPaginate: true,
          params: {
            token: this.token
          }
        })
        .then((response) => {
          if (response.data.length > 0) this.academy = response.data[0]
          this.initialLoad = false
        })
        .catch((err) => {
          this.initialLoad = false
        })
    },
    register() {
      this.isLoading = true
      const form = this.$refs.PlayerForm.form
      const representatives = this.$refs.PlayerForm.representatives

      let mother_dni_picture = null
      let father_dni_picture = null
      let other_dni_picture = null

      for (let representative of representatives) {
        if (parseInt(representative.type) === 1) {
          mother_dni_picture = representative.url_dni_picture
        } else if (parseInt(representative.type) === 2) {
          father_dni_picture = representative.url_dni_picture
        } else {
          other_dni_picture = representative.url_dni_picture
        }
        delete representative.url_dni_picture
        delete representative.key
      }

      let payload = {
        id_academy: this.academy.id,
        first_name: form.first_name,
        last_name: form.last_name,
        gender: form.gender,
        birthdate: form.birthdate,
        address: form.address,
        height: form.height,
        weight: form.weight,
        position: form.position,
        skillful_foot: form.skillful_foot,
        id_category: form.id_category,
        id_subcategory: form.id_subcategory,
        favorite_player: form.favorite_player,
        favorite_team: form.favorite_team,
        url_profile_picture: form.url_profile_picture,
        url_dni_picture: form.url_dni_picture,
        mother_dni_picture,
        father_dni_picture,
        other_dni_picture,
        representatives: JSON.stringify(representatives)
      }

      if (form.dni && form.dni !== "") payload.dni = form.dni

      this.$store
        .dispatch("global/create", {
          payload,
          route: "/player/save"
        })
        .then((response) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("player", response.code, this.$snotify)
          })
          if ([200, 201].includes(response.code)) {
            this.formSubmitted = true
          }
        })
        .catch((error) => {
          console.log("player register error:", error)
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    debouncedResize() {
      this.windowWidth = window.screen.width
    }
  },
  created() {
    this.getAcademy()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  }
}
</script>
