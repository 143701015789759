<template>
  <div v-if="initialLoad">
    <div class="flex justify-center">
      <t-loader extraClass="mx-auto border-black" />
    </div>
  </div>
  <div v-else-if="academy.id">
    <div class="flex justify-center items-center">
      <horizontal-stepper
        :steps="steps"
        @completed-step="completeStep"
        @active-step="isStepActive"
        @stepper-finished="isStepActive"
        :locale="'es'"
        ref="stepper"
      >
      </horizontal-stepper>
    </div>
    <div v-show="step === 1">
      <h1 class="subtitle-text mt-5">Información personal</h1>

      <div class="flex flex-col md:flex-row mt-5 items-end">
        <t-input-group
          :feedback="
            showValidation && errors.first('first_name')
              ? errors.first('first_name')
              : ''
          "
          :variant="
            showValidation && errors.first('first_name') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:ml-2 md:mr-2"
          :label="'Nombres'"
        >
          <t-input
            v-validate="'required|max:200'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.first_name"
            name="first_name"
            placeholder="Ingrese los nombres"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="
              showValidation && errors.first('first_name') ? 'danger' : ''
            "
          />
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('last_name')
              ? errors.first('last_name')
              : ''
          "
          :variant="showValidation && errors.first('last_name') ? 'danger' : ''"
          class="w-full md:w-1/2 md:ml-2 md:mr-2"
          :label="'Apellidos'"
        >
          <t-input
            v-validate="'required'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.last_name"
            name="last_name"
            placeholder="Ingrese los apellidos"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="
              showValidation && errors.first('last_name') ? 'danger' : ''
            "
          />
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('gender')
              ? errors.first('gender')
              : ''
          "
          :variant="showValidation && errors.first('gender') ? 'danger' : ''"
          class="w-full md:w-1/2 md:ml-2 md:mr-2"
          :label="'Sexo'"
        >
          <t-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.gender"
            name="gender"
            placeholder="Seleccione una opción"
            :disabled="isLoading || disableInputs"
            textAttribute="name"
            valueAttribute="id"
            :options="[
              {
                id: 1,
                name: 'Femenino'
              },
              {
                id: 2,
                name: 'Masculino'
              }
            ]"
            :variant="showValidation && errors.first('gender') ? 'danger' : ''"
          ></t-select>
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('dni') ? errors.first('dni') : ''
          "
          :variant="showValidation && errors.first('dni') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Cédula de identidad'"
        >
          <t-input
            v-validate="'numeric'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.dni"
            name="dni"
            placeholder="Ingrese el número de cedula de identidad"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="showValidation && errors.first('dni') ? 'danger' : ''"
          />
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('address')
              ? errors.first('address')
              : ''
          "
          :variant="showValidation && errors.first('address') ? 'danger' : ''"
          class="w-full md:w-1/2 md:ml-2 md:mr-2"
          :label="'Dirección'"
        >
          <t-input
            v-validate="'required'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.address"
            name="address"
            placeholder="Ingrese la dirección"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="showValidation && errors.first('address') ? 'danger' : ''"
          />
        </t-input-group>
        <div class="w-full md:w-1/2 md:ml-2 md:mr-2"></div>
      </div>

      <div class="flex flex-col md:flex-row items-end justify-around my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('url_carnet_type')
              ? errors.first('url_carnet_type')
              : ''
          "
          :variant="
            showValidation && errors.first('url_carnet_type') ? 'danger' : ''
          "
          class="w-full md:w-1/3 md:mr-0 md:pr-2"
          :label="'Foto tipo carnet'"
        >
          <cropper-upload
            :file.sync="form.url_profile_picture"
            :externalUrl="
              typeof form.url_profile_picture === 'string'
                ? form.url_profile_picture
                : null
            "
            title="Foto tipo carnet"
            :aspectRatio="3 / 4"
            height="250px"
            backgroundColor="bg-white"
          />
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('url_dni_picture')
              ? errors.first('url_dni_picture')
              : ''
          "
          :variant="
            showValidation && errors.first('url_dni_picture') ? 'danger' : ''
          "
          class="w-full md:w-1/3 md:mr-0 md:pr-2"
        >
          <label class="block text-left font-bold"
            >Foto de la cédula
            <small
              >(En caso de no tener, subir foto del acta de nacimiento)</small
            ></label
          >
          <t-upload-file
            :disabled="isLoading || disableInputs"
            :file.sync="form.url_dni_picture"
            :externalUrl="
              typeof form.url_dni_picture === 'string'
                ? form.url_dni_picture
                : ''
            "
            accept="image/*"
          />
        </t-input-group>
      </div>

      <h1 class="subtitle-text mt-5">Datos de jugador</h1>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('birthdate')
              ? errors.first('birthdate')
              : ''
          "
          :variant="showValidation && errors.first('birthdate') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Fecha de nacimiento'"
        >
          <t-datepicker
            v-model="form.birthdate"
            initial-view="year"
            lang="es"
            :closeOnSelect="true"
            v-validate="'required'"
            data-vv-validate-on="input"
            :maxDate="new Date()"
            name="birthdate"
            id="birthdate"
            placeholder="Seleccione la fecha"
            :disabled="isLoading || disableInputs"
            :variant="
              showValidation && errors.first('birthdate') ? 'danger' : ''
            "
          >
          </t-datepicker>
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('height')
              ? errors.first('height')
              : ''
          "
          :variant="showValidation && errors.first('height') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Estatura (en metros)'"
        >
          <t-input
            v-validate="'required|amount'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.height"
            name="height"
            placeholder="Ingrese la altura"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="number"
            :variant="showValidation && errors.first('height') ? 'danger' : ''"
          />
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('weight')
              ? errors.first('weight')
              : ''
          "
          :variant="showValidation && errors.first('weight') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Peso (en kilogramos)'"
        >
          <t-input
            v-validate="'required|amount'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.weight"
            name="weight"
            placeholder="Ingrese el peso"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="number"
            :variant="showValidation && errors.first('weight') ? 'danger' : ''"
          />
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('skillful_foot')
              ? errors.first('skillful_foot')
              : ''
          "
          :variant="
            showValidation && errors.first('skillful_foot') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:ml-2 md:mr-2"
          :label="'Pie dominante'"
        >
          <t-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.skillful_foot"
            name="skillful_foot"
            placeholder="Seleccione una opción"
            :disabled="isLoading || disableInputs"
            textAttribute="name"
            valueAttribute="id"
            :options="[
              {
                id: 'Izquierdo',
                name: 'Izquierdo'
              },
              {
                id: 'Derecho',
                name: 'Derecho'
              }
            ]"
            :variant="
              showValidation && errors.first('skillful_foot') ? 'danger' : ''
            "
          ></t-select>
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('favorite_player')
              ? errors.first('favorite_player')
              : ''
          "
          :variant="
            showValidation && errors.first('favorite_player') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:mr-2"
          :label="'Jugador favorito'"
        >
          <t-input
            v-validate="'required'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.favorite_player"
            name="favorite_player"
            placeholder="Ingrese el nombre del jugador favorito"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="
              showValidation && errors.first('favorite_player') ? 'danger' : ''
            "
          />
        </t-input-group>
        <t-input-group
          :feedback="
            showValidation && errors.first('favorite_team')
              ? errors.first('favorite_team')
              : ''
          "
          :variant="
            showValidation && errors.first('favorite_team') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:mr-2"
          :label="'Equipo favorito'"
        >
          <t-input
            v-validate="'required'"
            data-vv-validate-on="input"
            classes="bg-transparent"
            v-model="form.favorite_team"
            name="favorite_team"
            placeholder="Ingrese el nombre del equipo favorito"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="
              showValidation && errors.first('favorite_team') ? 'danger' : ''
            "
          />
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('id_category')
              ? errors.first('id_category')
              : ''
          "
          :variant="
            showValidation && errors.first('id_category') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:mr-2"
          :label="'Categoría'"
        >
          <t-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.id_category"
            name="id_category"
            placeholder="Seleccione una opción"
            :disabled="isLoading || disableInputs"
            textAttribute="name"
            valueAttribute="id"
            :options="categories"
            :variant="
              showValidation && errors.first('id_category') ? 'danger' : ''
            "
          ></t-select>
        </t-input-group>

        <t-input-group
          :feedback="
            showValidation && errors.first('id_subcategory')
              ? errors.first('id_subcategory')
              : ''
          "
          :variant="
            showValidation && errors.first('id_subcategory') ? 'danger' : ''
          "
          class="w-full md:w-1/2 md:mr-2"
          :label="'Subcategoría'"
        >
          <t-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.id_subcategory"
            name="id_subcategory"
            placeholder="Seleccione una opción"
            :disabled="isLoading || disableInputs"
            textAttribute="name"
            valueAttribute="id"
            :options="subcategories"
            :variant="
              showValidation && errors.first('id_subcategory') ? 'danger' : ''
            "
          ></t-select>
        </t-input-group>
      </div>

      <div class="flex flex-col md:flex-row items-end my-2">
        <t-input-group
          :feedback="
            showValidation && errors.first('position')
              ? errors.first('position')
              : ''
          "
          :variant="showValidation && errors.first('position') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Posición'"
        >
          <t-rich-select
            v-validate="'required'"
            data-vv-validate-on="input"
            v-model="form.position"
            name="position"
            id="position"
            placeholder="Seleccione una opción"
            :disabled="isLoading || disableInputs"
            noResultsText="No se encontraron resultados"
            searchBoxPlaceholder="Busca..."
            textAttribute="name"
            valueAttribute="id"
            :options="positions"
            :variant="
              showValidation && errors.first('position') ? 'danger' : ''
            "
          ></t-rich-select>
        </t-input-group>
        <div class="w-full md:w-1/2 md:mr-2"></div>
      </div>

      <h1 class="subtitle-text mt-5">Información de contacto</h1>

      <div class="flex flex-col md:flex-row items-start mt-5">
        <t-input-group
          :feedback="
            showValidation && errors.first('instagram')
              ? errors.first('instagram')
              : ''
          "
          :variant="showValidation && errors.first('instagram') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Usuario de Instagram'"
        >
          <t-input
            classes="bg-transparent"
            class="bg-transparent"
            v-model="form.instagram"
            name="instagram"
            placeholder="Ingrese el usuario de Instagram"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="
              showValidation && errors.first('instagram') ? 'danger' : ''
            "
          />
        </t-input-group>
        <t-input-group
          :feedback="
            showValidation && errors.first('phone') ? errors.first('phone') : ''
          "
          :variant="showValidation && errors.first('phone') ? 'danger' : ''"
          class="w-full md:w-1/2 md:mr-2"
          :label="'Teléfono móvil'"
        >
          <t-input
            classes="bg-transparent"
            v-model="form.phone"
            name="phone"
            placeholder="Ingrese el teléfono móvil"
            autocomplete="off"
            :disabled="isLoading || disableInputs"
            type="text"
            :variant="showValidation && errors.first('phone') ? 'danger' : ''"
          />
        </t-input-group>
      </div>
    </div>
    <div v-show="step === 2">
      <div class="flex items-center mt-5">
        <h1 class="subtitle-text mr-2">Información de representante(s)</h1>
      </div>

      <p class="mb-2" v-if="isAdult">
        <strong>Nota:</strong> Si eres mayor de edad no es obligatorio llenar
        los datos de representates
      </p>

      <div class="flex items-center">
        <t-button @click="addRepresentative"
          ><span class="font-bold text-sm text-white"
            >Agregar representante</span
          ></t-button
        >
      </div>

      <div
        class="my-3"
        v-for="(representative, index) in representatives"
        :key="representative.id ? representative.id : representative.key"
        :class="
          representatives[index + 1] ? 'border-gray-300 border-b-2 pb-5' : ''
        "
      >
        <div class="flex items-center justify-end mt-3">
          <t-button
            type="button"
            class="mr-3"
            variant="error"
            @click="deleteRepresentative(index)"
            >Eliminar</t-button
          >
        </div>
        <div class="flex flex-col md:flex-row mt-5 items-end">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_type`)
                ? errors.first(`representative_${representative.key}_type`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_type`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:ml-2 md:mr-2"
            :label="'Tipo'"
          >
            <t-select
              v-validate="'simple_required'"
              data-vv-validate-on="input"
              v-model="representative.type"
              :name="`representative_${representative.key}_type`"
              placeholder="Seleccione un tipo"
              :disabled="isLoading || disableInputs"
              textAttribute="name"
              valueAttribute="id"
              :options="representativeOptions()"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_type`)
                  ? 'danger'
                  : ''
              "
            ></t-select>
          </t-input-group>
          <div class="w-full md:w-1/2 md:ml-2 md:mr-2"></div>
        </div>

        <div class="flex flex-col md:flex-row mt-5 items-end">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_first_name`)
                ? errors.first(
                    `representative_${representative.key}_first_name`
                  )
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_first_name`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:ml-2 md:mr-2"
            :label="'Nombres'"
          >
            <t-input
              v-validate="'simple_required|max:200'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.first_name"
              :name="`representative_${representative.key}_first_name`"
              placeholder="Ingrese los nombres"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_first_name`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_last_name`)
                ? errors.first(`representative_${representative.key}_last_name`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_last_name`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:ml-2 md:mr-2"
            :label="'Apellidos'"
          >
            <t-input
              v-validate="'simple_required'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.last_name"
              :name="`representative_${representative.key}_last_name`"
              placeholder="Ingrese los apellidos"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_last_name`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>

        <div class="flex flex-col md:flex-row items-end my-2">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_address`)
                ? errors.first(`representative_${representative.key}_address`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_address`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:ml-2 md:mr-2"
            :label="'Dirección'"
          >
            <t-input
              v-validate="'simple_required'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.address"
              :name="`representative_${representative.key}_address`"
              placeholder="Ingrese la dirección"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_address`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_dni`)
                ? errors.first(`representative_${representative.key}_dni`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_dni`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Cédula de identidad'"
          >
            <t-input
              v-validate="'simple_required|numeric'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.dni"
              :name="`representative_${representative.key}_dni`"
              placeholder="Ingrese el número de cedula de identidad"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_dni`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>

        <div class="flex flex-col md:flex-row items-end justify-around my-2">
          <t-input-group
            class="w-full md:w-1/2 md:mr-0 md:pr-2"
            :label="'Foto de la cédula'"
          >
            <t-upload-file
              :disabled="isLoading || disableInputs"
              :file.sync="representative.url_dni_picture"
              :externalUrl="
                typeof representative.url_dni_picture === 'string'
                  ? representative.url_dni_picture
                  : ''
              "
              accept="image/*"
            />
          </t-input-group>
        </div>

        <div class="flex flex-col md:flex-row items-start mt-5">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_phone`)
                ? errors.first(`representative_${representative.key}_phone`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_phone`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Teléfono móvil'"
          >
            <t-input
              v-validate="'simple_required|phone'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.phone"
              :name="`representative_${representative.key}_phone`"
              placeholder="Ingrese el teléfono móvil"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_phone`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_local_phone`)
                ? errors.first(
                    `representative_${representative.key}_local_phone`
                  )
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_local_phone`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Teléfono de habitación'"
          >
            <t-input
              classes="bg-transparent"
              v-model="representative.local_phone"
              :name="`representative_${representative.key}_local_phone`"
              placeholder="Ingrese el teléfono de habitación"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_local_phone`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>

        <div class="flex flex-col md:flex-row items-start mt-5">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_email`)
                ? errors.first(`representative_${representative.key}_email`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_email`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Correo electrónico'"
          >
            <t-input
              v-validate="'simple_required|simple_email'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.email"
              :name="`representative_${representative.key}_email`"
              placeholder="Ingrese el correo electrónico"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="email"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_email`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
          <div class="hidden md:block md:w-1/2 md:ml-2 md:mr-2"></div>
        </div>

        <div class="flex flex-col md:flex-row items-start mt-5">
          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_workplace`)
                ? errors.first(`representative_${representative.key}_workplace`)
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_workplace`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Empresa donde labora'"
          >
            <t-input
              v-validate="'simple_required'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.workplace"
              :name="`representative_${representative.key}_workplace`"
              placeholder="Ingrese el nombre de la empresa"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_workplace`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>

          <t-input-group
            :feedback="
              showValidation &&
              errors.first(`representative_${representative.key}_profession`)
                ? errors.first(
                    `representative_${representative.key}_profession`
                  )
                : ''
            "
            :variant="
              showValidation &&
              errors.first(`representative_${representative.key}_profession`)
                ? 'danger'
                : ''
            "
            class="w-full md:w-1/2 md:mr-2"
            :label="'Cargo que ocupa'"
          >
            <t-input
              v-validate="'simple_required'"
              data-vv-validate-on="input"
              classes="bg-transparent"
              v-model="representative.profession"
              :name="`representative_${representative.key}_profession`"
              placeholder="Ingrese el cargo que ocupa"
              autocomplete="off"
              :disabled="isLoading || disableInputs"
              type="text"
              :variant="
                showValidation &&
                errors.first(`representative_${representative.key}_profession`)
                  ? 'danger'
                  : ''
              "
            />
          </t-input-group>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center mt-5">
      <t-button @click="previousStep" v-if="step > 1" variant="gray"
        >Atrás</t-button
      >

      <div v-if="step === 1"></div>

      <t-button v-if="step === 1" @click="nextStep" class="ml-auto"
        >Siguiente<t-loader v-if="isLoading" extraClass="ml-2"
      /></t-button>

      <t-button v-if="step === 2" @click="nextStep" class="ml-auto"
        >Guardar<t-loader v-if="isLoading" extraClass="ml-2"
      /></t-button>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-center flex-col">
      <div class="mx-auto">
        <img src="@/assets/img/error/403.svg" alt="No autorizado" />
      </div>
      <div class="mx-auto">
        <p class="text-primary-700 font-bold">
          Lo sentimos, no puedes registrarte si no utilizas el link provisto por
          tu academia
        </p>
      </div>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
import Empty from "./Empty.vue"
import HorizontalStepper from "vue-stepper"

export default {
  name: "PlayerForm",
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    },
    initialLoad: {
      type: Boolean,
      default: false
    },
    academy: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    HorizontalStepper
  },

  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        address: null,
        gender: null,
        dni: null,
        birthdate: null,
        height: null,
        weight: null,
        skillful_foot: null,
        favorite_player: null,
        favorite_team: null,
        position: null,
        instagram: null,
        phone: null,
        url_profile_picture: null,
        url_dni_picture: null
      },
      representatives: [],
      showValidation: false,
      isLoading: false,
      steps: [
        {
          id: 1,
          icon: "person",
          name: "first",
          title: "Jugador",
          subtitle: "",
          component: Empty,
          completed: false
        },
        {
          id: 2,
          icon: "group",
          name: "second",
          title: "Representantes",
          subtitle: "",
          component: Empty,
          completed: false
        }
      ],
      step: 1,
      positions: [
        {
          id: 1,
          name: "Arquero"
        },
        {
          id: 2,
          name: "Defensa"
        },
        {
          id: 3,
          name: "Mediocampo"
        },
        {
          id: 4,
          name: "Delantero"
        }
      ],
      representativeTypes: [
        {
          name: "Madre",
          id: 1,
          disabled: false
        },
        {
          name: "Padre",
          id: 2,
          disabled: false
        },
        {
          name: "Otro",
          id: 3,
          disabled: false
        }
      ]
    }
  },
  computed: {
    categories() {
      return this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
    },
    subcategories() {
      return this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )
    },
    isAdult() {
      const years_diff = this.$diffFrom(this.form.birthdate, "years")
      return years_diff >= 18.0
    }
  },
  methods: {
    representativeOptions() {
      for (let element of this.representativeTypes) {
        element.disabled = false
        if (
          Object.values(this.representatives.map((x) => x.type)).some(
            (x) => parseInt(x) === element.id
          )
        )
          element.disabled = true
      }
      return this.representativeTypes
    },
    addRepresentative() {
      if (this.representatives.length === 2) {
        return this.$snotify.error(
          "No puedes escoger más de 2 representantes",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.representatives.push({
        key: Date.now(),
        type: "",
        first_name: "",
        last_name: "",
        dni: "",
        url_dni_picture: null,
        phone: "",
        local_phone: "",
        email: "",
        address: "",
        workplace: "",
        profession: ""
      })
    },
    deleteRepresentative(index) {
      if (!this.isAdult && this.representatives.length === 1) {
        return this.$snotify.error(
          "No se pueden eliminar todos los representantes",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.representatives.splice(index, 1)
    },
    validValidation(data) {
      this.canContinue = data.valid
    },
    triggerNextStep() {
      let stepIndex = this.steps.findIndex((x) => x.id === this.step)
      if (stepIndex !== -1) {
        this.steps[stepIndex].completed = true
        this.changeStepHeader("forward", stepIndex)
        this.step++
        this.$emit("scrollTo", { ref: "formBox", x: 0, y: 0 })
      }
    },
    nextStep() {
      if (this.step === 1) {
        this.isValid(this.form)
      }

      if (this.step === 2) {
        this.isValid(this.representatives)
      }
    },
    previousStep() {
      let stepIndex = this.steps.findIndex((x) => x.id === this.step)

      if (stepIndex !== -1) {
        this.steps[stepIndex].completed = false
        this.steps[stepIndex - 1].completed = false
        this.changeStepHeader("backwards", stepIndex)
        this.step--
      }
    },
    changeStepHeader(direction, step) {
      let children = Array.from(
        Array.from(document.getElementsByClassName("steps-wrapper"))[0].children
      )
      if (direction === "forward") {
        children.forEach((el, index) => {
          if (index !== step + 1) el.classList.remove("activated")
          if (index === step + 1) {
            el.classList.add("activated")
            el.classList.remove("deactivated")
          }
        })
      } else {
        children.forEach((el, index) => {
          if (index !== step - 1) {
            el.classList.remove("activated")
            if (!this.steps[index].completed) el.classList.add("deactivated")
          }
          if (index === step - 1) {
            el.classList.add("activated")
            el.classList.remove("deactivated")
          }
        })
      }
    },
    completeStep(payload) {
      null
    },
    isStepActive(payload) {
      null
    },

    isValid(data) {
      this.$validator.validateAll(data).then((isValid) => {
        if (isValid === true) {
          if (this.step === 1 && !this.form.url_profile_picture) {
            this.$snotify.error("La foto tipo carnet es obligatoria", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
            return
          }
          if (this.step === 1 && !this.form.url_dni_picture) {
            this.$snotify.error(
              "La foto de la cédula es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
            return
          }

          if (this.step === 2) {
            if (!this.isAdult && this.representatives.length === 0)
              return this.$snotify.error(
                "Debes introducir los datos de al menos un representate",
                "Error",
                {
                  timeout: 5000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  titleMaxLength: 40,
                  bodyMaxLength: 100,
                  position: "rightBottom"
                }
              )
            for (let representative of this.representatives) {
              if (!representative.url_dni_picture)
                return this.$snotify.error(
                  "La foto de la cédula de los representantes es obligatoria",
                  "Error",
                  {
                    timeout: 5000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    titleMaxLength: 40,
                    bodyMaxLength: 100,
                    position: "rightBottom"
                  }
                )
            }
          }
          if (this.step === 2) {
            this.isLoading = true
            this.$emit("submit")
          } else {
            this.triggerNextStep()
          }
        }
      })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.showValidation = true
    this.getItems("/category/all", "category", true)
    this.getItems("/subcategory/all", "subcategory", true)
  },
  watch: {}
}
</script>

<style lang="scss">
.step-title h4 {
  font-family: "Montserrat";
}
.step i {
  background-color: rgb(172, 131, 48) !important;
}
.step.activated i {
  background-color: #e09f1f !important;
}
.top .steps-wrapper .step.deactivated i {
  background-color: #2c2c2c !important;
}

.stepper-box {
  box-shadow: none !important;
}

.stepper-box .content {
  box-shadow: none !important;
  height: fit-content !important;
  min-height: fit-content !important;
  padding: 1rem;
}

.stepper-box .content {
  display: none !important;
}
.stepper-box .bottom {
  display: none !important;
}
.stepper-box {
  min-height: 0px !important;
  height: fit-content !important;
  width: 100%;
}

.steps-content {
  padding: 1rem;
}

@media (max-width: 768px) {
  .steps-wrapper * {
    display: flex !important;
  }
  .steps-wrapper {
    width: 100% !important;
  }
  .step-title {
    font-size: 0.8rem !important;
  }
}
</style>
