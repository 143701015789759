<template>
  <div></div>
</template>
<script>
export default {
  props: ["clickedNext", "currentStep"],
  data() {
    return {}
  }
}
</script>
